<template>
  <div class="login-box">
    <loginHeader :title="$route.meta.title"></loginHeader>
    
    
    <div>
      <div class="bind_box">
        <div class="input_box">
          <input type="text" v-model="phone" placeholder="请输入账号" />
        </div>
        <div class="input_box">
          <input type="password" v-model="password" placeholder="请输入密码" />
        </div>
        
      </div>
      <div>
        <span class="btn " @click="toLogin">登录</span>
        
      </div>
      
    </div>

    
  </div>
</template>
<script>
import {Toast} from "vant";
import {selectBanner,loginByTelMsg,login,loginByAccount,loginByWx} from "../../utils/api";
import "../../assets/css/loginRegister.scss";
import {mapMutations} from "vuex";
export default {
  data() {
    return {
      navIndex: 1,
      //   手机登录
      mobile: null,
      code: null, //验证码
      codeSwitch: true, //验证码状态
      mobileAfter: false, //验证码状态
      times: 60, //秒数
      phone: null,
      password: null,
      redirectPath: null,
      isWx: null,
    };
  },
 
  // watch: {
  //   $route: {
  //     handler(val, oldval) {
  //       if (this.$route.query.code && val != oldval) {
  //         this.toLoginByWx(this.$route.query.code);
  //       }
  //     },
  //     // 深度观察监听
  //     deep: true,
  //   },
  // },
  mounted() {
    console.log('打开中')
    this.$cookies.remove("Authorization");
    // localStorage.removeItem("Authorization");
    this.redirectPath = this.$route.query.redirect;
    
    // if (this.getQueryVariable("code")) {
    //   this.toLoginByWx(this.getQueryVariable("code"));
    // }
    // this.isWx =
    //   navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    // console.log(this.isWx);

    // if(this.isWx && !this.getQueryVariable("code")){
    //   this.wxLogin();
    // }
  },
  methods:{
    ...mapMutations(["changeLogin","saveBottomNav"]),
    checkMobile(){
      let myreg = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
    },
    sendCode() {
      console.log("in");
      let that = this;
      let myreg = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
      if(!myreg.test(this.mobile)){
        Toast("请输入正确的手机号");
      }else if (that.mobile && myreg.test(this.mobile)) {
        login({
          tel: that.mobile,
        })
          .then((res) => {
            that.mobileAfter=true;
            that.codeSwitch = false;
            let timer = setInterval(() => {
              that.times--;
              if (that.times == 0) {
                that.codeSwitch = true;
                that.times = 60;
                clearInterval(timer);
              }
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Toast("手机号码不能为空");
      }
    },
    toLogin() {
      let that = this;
      
      if (that.navIndex == 0) {
        if(!this.mobile){
          Toast("手机号码不能为空");
          return false;
        }
        if(!this.code){
          Toast("验证码不能为空");
          return false;
        }
        Toast.loading({
          message: "正在登陆...",
          duration: "none",
        });
        loginByTelMsg({
          mobile: that.mobile,
          msgCode: that.code,
        }).then((res) => {
          that.changeLogin({Authorization: res.data.token});
          Toast.success("登录成功");
          setTimeout(() => {
            Toast.clear();
            // that.$router.replace(this.$route.query.redirect || "user");
            that.$router.replace("index");
          }, 500);
        });
      } else {
        console.log('dl')
        if(!this.phone){
          Toast("账号不能为空");
          return false;
        }
        if(!this.password){
          Toast("密码不能为空");
          return false;
        }
        Toast.loading({
          message: "正在登陆...",
          duration: "none",
        });
        loginByAccount({
          mobile: this.phone,
          password: this.password,
        }).then((res) => {
           
          that.changeLogin({Authorization: res.data.token});
          // this.$cookies.set('Authorization',res.data.token)
          Toast.success("登录成功");
          setTimeout(() => {
            Toast.clear();
            // that.$router.replace(this.$route.query.redirect || "index");
            this.saveBottomNav('index');
            that.$router.replace("index");
            // this.toGo('index')
            // console.log(this.$cookies.get('Authorization'))
          }, 500);
        })
        
      }
    }, 
    wxLogin() {
      let redirect_uri = encodeURIComponent(
        // "https://ytmwap.gz-jiayou.com/#/login"
        "https://m.mizun.cn/#/login"
      );
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd49a1a198c7a86f5&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`;
    },
    toLoginByWx(code) {
      let that = this;
      Toast.loading({
        message: "正在登陆...",
        duration: "none",
      });
      loginByWx({
        code: code,
      })
        .then((res) => {
          console.log('微信登录');
          console.log(res);
          that.changeLogin({Authorization: res.data.token});
          Toast.success("登录成功");
          setTimeout(() => {
            Toast.clear();
            that.$router.replace(this.$route.query.redirect || "user");
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            Toast.clear();
          }, 1000);
        });
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    toForget() {
      this.$router.push("/findPwd");
    },
    toRegister() {
      this.$router.push("/register");
    },
    toagreement() {
      this.$router.push("/argument");
    },
  },
};
</script>

